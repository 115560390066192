<template>
  <Browse
    :columns="[
      'employee',
      'check_in_date_time',
      'check_out_date_time',
      'note',
    ]"
    :formats="{
      check_in_date_time: 'DateTime',
      check_out_date_time: 'DateTime',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="clock_ins"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
